var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"formUploadProducts"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.searchProduct($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Seriales","label-for":"serial","description":"Selecciona el archivo con los seriales correspondientes"}},[_c('validation-provider',{attrs:{"name":"Seriales","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"placeholder":"Selecciona...","drop-placeholder":"Drop file here..."},model:{value:(_vm.fileProducts),callback:function ($$v) {_vm.fileProducts=$$v},expression:"fileProducts"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Tipo de Producto","label-for":"product_type_id"}},[_c('validation-provider',{attrs:{"name":"Tipo de Producto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"product_type_id","options":_vm.productTypeOptions},model:{value:(_vm.productTypeId),callback:function ($$v) {_vm.productTypeId=$$v},expression:"productTypeId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Número de transporte","label-for":"number_order"}},[_c('validation-provider',{attrs:{"name":"Número de transporte","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","id":"number_order"},model:{value:(_vm.numberOrder),callback:function ($$v) {_vm.numberOrder=$$v},expression:"numberOrder"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Descripción","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"Descripción","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","id":"description"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","label":"Loading..."}}),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs',"type":"submit"}},[_vm._v(" Subir ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }