<template>
  <div>

    <!-- User Info: Input Fields -->
    <validation-observer ref="formUploadProducts">

      <b-form
        @submit.prevent="searchProduct">
        <b-row>

          <!-- Field: Username -->
          <b-col
            cols="12"
            md="4"
          >

            <b-form-group
                label="Seriales"
                label-for="serial"
                description="Selecciona el archivo con los seriales correspondientes"
              >
              <validation-provider
                  #default="{ errors }"
                  name="Seriales"
                  rules="required"
                >

                <!-- Styled -->
                <b-form-file
                  v-model="fileProducts"
                  placeholder="Selecciona..."
                  drop-placeholder="Drop file here..."
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>

          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            
            <b-form-group
              label="Tipo de Producto"
              label-for="product_type_id"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Tipo de Producto"
                  rules="required"
                >

                <b-form-select
                  id="product_type_id"
                  v-model="productTypeId"
                  :options="productTypeOptions"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              
            </b-form-group>

          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Número de transporte"
              label-for="number_order"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Número de transporte"
                  rules="required"
                >

                <b-form-input
                  type="text"
                  id="number_order"
                  v-model="numberOrder"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>

          </b-col>


          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Descripción"
              label-for="description"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Descripción"
                  rules="required"
                >

                <b-form-input
                  type="text"
                  id="description"
                  v-model="description"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>

          </b-col>
          
        </b-row>

        <!-- Action Buttons -->
        <b-spinner
          v-show="loading"
          variant="primary"
          label="Loading..."
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
           />

        <b-button
          v-show="!loading"
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
        >
          Subir
        </b-button>
      
      </b-form>
    </validation-observer>

    
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BFormFile, BFormSelect, BSpinner
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { required, } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {constants} from "@/helpers/constants"

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    BFormSelect,
    BSpinner,
  },
  data() {
    return {
      host: constants.hostUrl,
      accessToken: localStorage.getItem('accessToken'),
      fileProducts: null,
      productTypeId: '',
      numberOrder: '',
      description: '',
      loading: false,
      required,
      productTypeOptions: [
        {
          text: 'Selecciona una opción',
          value: null,
          disabled: true,
        },
        {
          text: 'BOLD NEO',
          value: '1',
        },
        {
          text: 'BOLD PLUS',
          value: '2',
        },
        {
          text: 'BOLD SMART',
          value: '3',
        },
        {
          text: 'BOLD PRO',
          value: '4',
        },
      ],
    }
  },
  setup(props) {
    const countryOptions = [
      { label: 'Colombia', value: 1 },
    ]

    const serializedOptions = [
      { label: 'Texto', value: 0 },
      { label: 'JSON', value: 1 },
    ]

    return {
      countryOptions,
      serializedOptions,
    }
  },
  created() {
  },
  methods: {
    searchProduct() {
      event.preventDefault()
      this.$refs.formUploadProducts.validate().then(success => {
        if (success) {

          this.loading = true

          let headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + this.accessToken
          }

          let formData = new FormData()
          formData.append('products', this.fileProducts)
          formData.append('product_type_id', this.productTypeId)
          formData.append('number_order', this.numberOrder)
          formData.append('description', this.description)

          axios.post(this.host + '/api/front-web/products-import', formData, { headers }).then(response => {
            console.log('products-import: ', response)

            this.loading = false

            if(response.data.status) {

              this.fileProducts = null
              this.productTypeId = ''
              this.numberOrder = ''
              this.description = ''

              this.$toast({
                component: ToastificationContentVue,
                position: 'top-right',
                props: {
                  title: `Satisfactorio`,
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })

            } else {
              this.$toast({
                component: ToastificationContentVue,
                position: 'top-right',
                props: {
                  title: `Advertencia`,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                  text: response.data.message,
                },
              })
            }
          }).catch( err => {
            console.log(err)
          })

        }
      })


    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
